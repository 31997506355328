<template>
	<div class="d-flex flex-column flex-root">
    <router-view></router-view>
    <div class="info-cookie">    
      <!-- <div class="alert alert-custom alert-white shadow fade show py-2" role="alert">
        <div class="alert-icon"><i class="flaticon-info icon-md"></i></div>
        <div class="alert-text">This website uses cookies to make BiPart work for you. By using this site, you agree to our cookie policy</div>
        <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="ki ki-close"></i></span>
            </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
// @import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-4.jpg";
    }
  }
};
</script>
